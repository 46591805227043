@import '../../../../style/vars';

.addComment {
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 0 $spacing-s;

  input {
    background-color: transparent;
    border: 0;
    border-bottom: 1px solid $color-tertiary;
    color: $color-foreground;
    flex: 1;
    font: inherit;
    margin: 0 0 $spacing-s 0;
    outline: 0;
    padding: $spacing-xs;
    transition: border-bottom 200ms ease-in-out;
  }

  .controls {
    align-self: flex-end;
    display: none;

    button {
      border: 0;
      border-radius: 2px;
      color: $color-foreground;
      font: inherit;
      outline: 0;
      padding: $spacing-xs $spacing-s;

      &:active,
      &:focus {
        outline: 1px solid $color-highlight;
      }
    }

    .secondary {
      background: none;
      margin-right: $spacing-s;
    }

    .primary {
      background-color: $color-highlight;
    }
  }

  &.active {
    input {
      border-bottom: 1px solid $color-foreground;
    }

    .controls {
      display: block;
    }
  }
}
