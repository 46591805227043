@import '../../style/vars';
@import '../../style/form';

.cardWrap {
  background-color: $color-secondary;
  border: 1px solid $color-tertiary;
  border-radius: 2px;
  color: $color-foreground;
  font: inherit;
  max-height: 4rem;
  padding: $spacing-s $spacing-s;

  &.error {
    border: 1px solid $color-highlight;
    border-radius: 2px 2px 0 0;
  }
}

.form {
  button {
    max-height: 4rem;
  }
}

.or {
  border-bottom: 1px solid $color-tertiary;
  margin: $spacing-m 0;
  position: relative;
  width: 100%;

  .inner {
    background-color: $color-primary;
    left: calc(50% - 12.5px);
    padding: 0 $spacing-xs;
    position: absolute;
    top: -12px;
  }
}
