@import '../../style/vars';
@import '../../style/form';

.page {
  color: $color-foreground;
  display: flex;
  justify-content: center;

  .wrap {
    display: flex;
    flex-direction: column;
    max-width: 60rem;
    padding: $spacing-m 0;
    width: 100%;

    h1 {
      font-size: 4.2rem;
      font-weight: 700;
      line-height: 4.2rem;
      margin: 0 0 $spacing-m 0;
      text-transform: uppercase;
    }

    .formWrap {
      background-color: $color-primary;
      border: 1px solid $color-tertiary;
      border-radius: 2px;
      padding: $spacing-m;

      h2 {
        align-items: center;
        display: flex;
        font-size: 2.8rem;
        justify-content: space-between;
        margin: 0 0 $spacing-s 0;
      }

      label {
        small {
          color: $color-tertiary;
        }

        .modalTrigger {
          background: none;
          background-color: none;
          color: $color-highlight;
          display: inline;
          font-weight: normal;
          margin: 0;
          padding: 0;
          text-align: left;

          &:hover,
          &:active {
            text-decoration: underline;
          }
        }
      }

      .error {
        a {
          color: #fff;
        }
      }

      button {
        max-height: 4rem;
      }

      button.link {
        background: none;
        color: #fff;
        font-weight: normal;
      }
    }
  }
}

.overlay {
  align-items: center;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  height: 100vh;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  width: 100vw;

  .modal {
    background-color: $color-primary;
    border: 1px solid $color-tertiary;
    border-radius: 2px;
    color: $color-foreground;
    max-width: 80rem;
    padding: $spacing-m;
    position: relative;
    width: 100%;

    .close {
      background: none;
      border: 0;
      color: $color-foreground;
      cursor: pointer;
      font-size: 2rem;
      margin: 0;
      padding: 0;
      position: absolute;
      top: $spacing-xs;
      right: $spacing-s;
    }

    h1 {
      margin: 0 0 $spacing-s 0;
    }

    img {
      border: 1px solid $color-secondary;
      border-radius: 2px;
      max-width: 100%;
    }
  }
}
