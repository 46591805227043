@import '../../style/vars';

.footer {
  align-items: center;
  background-color: #000;
  color: $color-foreground;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: $spacing-m;

  @media (max-width: 700px) {
    align-items: stretch;
  }

  nav {
    align-items: center;
    display: flex;
    flex: 1;
    flex-direction: column;
    margin: 0 0 $spacing-s 0;

    @media (max-width: 700px) {
      flex-direction: column-reverse;
    }

    ul {
      display: flex;
      list-style: none;
      margin: 0;
      padding: 0;

      li {
        margin: 0 $spacing-l 0 0;
        padding: 0 0 1.5rem 0;
        position: relative;

        &:last-child {
          margin-right: 0;
        }

        &:hover {
          .dot {
            background-color: $color-highlight;
            transform: scale(1);
          }

          a {
            color: $color-tertiary;
          }
        }

        .dot {
          background-color: $color-highlight;
          border-radius: 50%;
          bottom: 0;
          height: 6px;
          left: calc(50% - 3px);
          position: absolute;
          transform: scale(0);
          transition: transform 100ms ease-in-out;
          width: 6px;
          z-index: 10;
        }

        a {
          color: $color-foreground;
          font-size: 1.2rem;
          text-decoration: none;
          transition: color 200ms ease-in-out;

          .spacer {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            z-index: 50;
          }
        }
      }

      @media (max-width: 700px) {
        flex-direction: column;

        li {
          margin: 0;
          text-align: center;
        }
      }
    }

    ul.social {
      flex-direction: row;

      li {
        margin: 0 $spacing-l 0 0;

        &:last-child {
          margin: 0;
        }

        a {
          font-size: 2.2rem;
        }
      }
    }
  }

  .legal {
    align-self: stretch;
    color: $color-tertiary;
    display: flex;
    font-size: 1.2rem;
    text-transform: uppercase;

    .copyright {
      text-align: center;
    }

    div {
      flex-basis: 0;
      flex: 1;
    }

    .version {
      text-align: right;
    }
  }
}
