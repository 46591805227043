@import '../../../../style/vars';

.contest {
  align-items: center;
  background-color: $color-primary;
  border: 1px solid $color-tertiary;
  border-radius: 2px;
  color: $color-foreground;
  display: flex;
  justify-content: space-between;
  margin: 0 0 $spacing-s 0;
  padding: $spacing-m;
  position: relative;
  z-index: 5;

  .text {
    display: flex;
    flex-direction: column;

    h3 {
      align-items: flex-end;
      display: flex;
      line-height: 3.6rem;
      margin: 0;

      a {
        color: $color-foreground;
        text-decoration: none;

        span {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
      }
    }

    .info {
      display: flex;

      @media (max-width: 750px) {
        flex-direction: column;
      }

      small {
        font-size: 1.4rem;
        font-weight: normal;
        line-height: 2.2rem;
        margin: 0 0 0 $spacing-s;

        .prize {
          margin: 0 $spacing-xs 0 0;
          position: relative;
          z-index: 500;
        }
      }
    }
  }

  .fa {
    font-size: 3.2rem;
  }
}
