@import '../../style/vars';
@import '../../style/form';

.toast {
  background: $color-secondary !important;
  border: 1px solid $color-tertiary;
}

.toastBody {
  color: $color-foreground;
}

.toastProgress {
  background: $color-highlight !important;
}

.page {
  display: flex;
  flex-direction: column;
  padding: $spacing-m;

  h1 {
    align-self: center;
    color: $color-foreground;
    font-size: 4.2rem;
    font-weight: bold;
    line-height: 42px;
    margin: 0 0 $spacing-m 0;
    max-width: 100rem;
    text-transform: uppercase;
    width: 100%;
  }

  .wrapper {
    align-self: center;
    display: flex;
    max-width: 100rem;
    width: 100%;

    .wrapLeft {
      flex: 1;
      margin: 0 $spacing-s 0 0;
    }

    .wrapRight {
      max-width: 30rem;
      width: 100%;
    }

    .formWrap {
      background-color: $color-primary;
      border: 1px solid $color-tertiary;
      border-radius: 2px;
      color: $color-foreground;
      display: flex;
      flex-direction: column;
      margin-bottom: $spacing-s;
      padding: $spacing-m;

      p {
        line-height: 2.2rem;
        margin: 0 0 $spacing-s 0;

        a {
          color: $color-highlight;
        }
      }

      small {
        font-style: italic;
        margin: -$spacing-m 0 $spacing-m 0;
      }

      &.stats {
        form {
          margin: 0 0 $spacing-m 0;
        }

        .row {
          display: flex;
          flex: 1;
          flex-direction: column;

          .stat {
            display: flex;
            flex: 1;
            flex-direction: column;
            text-align: center;

            .label {
              font-size: 1.8rem;
            }

            .value {
              align-items: center;
              display: flex;
              font-size: 5.2rem;
              font-weight: bold;
              justify-content: center;

              img {
                margin: 0 0 0 $spacing-s;
                max-height: 6rem;
              }
            }
          }
        }

        .button {
          align-items: center;
          background-color: $color-highlight;
          border: none;
          border-radius: 2px;
          color: #fff;
          cursor: pointer;
          display: flex;
          font: inherit;
          font-weight: bold;
          justify-content: center;
          margin: $spacing-m 0 0 0;
          padding: $spacing-xs $spacing-s;

          img {
            margin: 0 0 0 $spacing-xs;
            max-height: 2rem;
          }
        }

        .history {
          background: none;
          border: none;
          color: #fff;
          margin: $spacing-s 0 0 0;
          text-decoration: underline;
        }
      }

      h2 {
        font-weight: normal;
        margin: 0 0 $spacing-m 0;
      }

      ul {
        margin: 0;
        padding: 0 0 0 $spacing-m;
        li {
          line-height: 2.2rem;
          margin: 0 0 $spacing-s 0;

          a {
            color: $color-foreground;
          }

          small {
            color: $color-tertiary;
            margin: 0 0 0 $spacing-xs;

            a {
              color: $color-tertiary;
            }
          }
        }
      }
    }

    .linkBox {
      position: relative;

      h2 {
        margin: 0;

        a {
          color: $color-foreground;
          text-decoration: none;

          span {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
          }

          .innerWrap {
            align-items: center;
            display: flex;
            justify-content: space-between;
          }
        }
      }
    }

    .refLink {
      font-size: 1.6rem;
      line-height: 2.2rem;
    }

    .copyButton {
      background: transparent;
      display: inline;
      padding: 0;
    }

    @media (max-width: 800px) {
      & {
        flex-direction: column-reverse;

        .wrapLeft {
          margin: 0;
        }

        .wrapRight {
          max-width: 100%;

          .stats {
            .row {
              flex-direction: row;
            }
          }
        }
      }
    }

    @media (max-width: 650px) {
      & {
        .wrapRight {
          .stats {
            .row {
              flex-direction: column;
            }
          }
        }
      }
    }
  }
}

.overlay {
  align-items: center;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  height: 100vh;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  width: 100vw;

  .modal {
    background-color: $color-primary;
    color: #fff;
    border: 1px solid $color-tertiary;
    border-radius: 2px;
    max-width: 50rem;
    padding: $spacing-m $spacing-s $spacing-s $spacing-s;
    position: relative;
    width: 100%;

    h2 {
      margin: 0 0 $spacing-s 0;
    }

    .close {
      background: none;
      border: 0;
      color: #fff;
      cursor: pointer;
      font-size: 2.4rem;
      position: absolute;
      right: $spacing-s;
      top: $spacing-s;
    }

    table {
      border-collapse: collapse;
      border-radius: 2px;
      overflow: hidden;
      width: 100%;

      th {
        background-color: $color-highlight;
        text-align: left;
      }

      td,
      th {
        padding: $spacing-s;

        &.empty {
          color: darken(#fff, 10%);
          text-align: center;
        }
      }

      tr {
        background-color: $color-secondary;
      }

      tr:nth-child(odd) {
        background-color: darken($color-secondary, 5%);
      }

      .amount {
        text-align: right;
      }
    }
  }
}
