@import '../../style/vars';

.page {
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: $spacing-m;

  h1 {
    color: $color-foreground;
    font-size: 4.2rem;
    font-weight: bold;
    line-height: 42px;
    margin: 0 0 $spacing-s 0;
  }

  .backLink {
    color: $color-foreground;
  }

  .wrapper {
    align-self: center;
    background-color: $color-primary;
    border: 1px solid $color-tertiary;
    color: $color-foreground;
    margin-top: $spacing-s;
    padding: $spacing-m;
    width: 100%;

    a {
      color: $color-foreground;
    }
  }
}

.overlay {
  align-items: center;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  height: 100vh;
  left: 0;
  justify-content: center;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 50;
}

.modal {
  background-color: $color-primary;
  border: 1px solid $color-tertiary;
  border-radius: 2px;
  color: $color-foreground;
  display: flex;
  flex-direction: column;
  outline: none;
  padding: $spacing-m;

  h2 {
    margin: 0 0 $spacing-m 0;
  }

  img {
    align-self: center;
    border: 1px solid $color-foreground;
    border-radius: 2px;
    margin: 0 0 $spacing-m 0;
  }

  .buttonWrap {
    display: flex;
    justify-content: flex-end;

    button {
      border: 0;
      border-radius: 1px;
      color: $color-foreground;
      cursor: pointer;
      font: inherit;
      padding: $spacing-s $spacing-m;

      &:disabled {
        opacity: 0.8;
      }

      &.primary {
        background-color: $color-highlight;
        margin: 0 0 0 $spacing-s;
      }

      &.secondary {
        background: none;
      }
    }
  }
}
