@import '../../style/vars';

.adbar {
  background-color: #838383;
  border-radius: 5px 0 0 5px;
  color: #fff;
  display: flex;
  flex-direction: column;
  padding: $spacing-s;
  position: fixed;
  right: 0;
  z-index: 100;

  h3 {
    margin: 0;
  }

  .placeholder {
    background-color: rgba(0, 0, 0, 0.5);
    margin: 0 0 $spacing-xs 0;

    &:last-of-type {
      margin: 0;
    }
  }
}
