@import '../../style/vars';

.page {
  color: $color-foreground;
  display: flex;
  flex-direction: column;

  .hero {
    background-image: url('../../assets/hero-03.jpg');
    background-position: top;
    display: flex;
    flex-direction: column;
    height: 90vh;
    margin: 0 0 -36rem 0;
    min-height: 420px;
    position: relative;
    z-index: 5;

    @media (max-width: 675px) {
      height: 80vh;
    }

    .wrap {
      align-items: flex-start;
      background: linear-gradient(
        180deg,
        rgba($color-background, 0.1) 0%,
        rgba($color-background, 0.5) 80%,
        rgba($color-primary, 1) 100%
      );
      display: flex;
      flex: 1;
      flex-direction: column;
      justify-content: center;

      .innerWrap {
        align-items: flex-start;
        display: flex;
        font-size: 6.4rem;
        flex-direction: column;
        margin: -17rem 0 0 0;
        padding: 0 $spacing-l;

        @media (max-width: 1024px) {
          font-size: 4.8rem;
        }

        @media (max-width: 710px) {
          font-size: 3.6rem;
        }

        h1 {
          font-size: 100%;
          margin: 0 0 $spacing-xs 0;
          text-shadow: #000 0px 0px 3px;
          text-transform: uppercase;

          a {
            color: $color-foreground;
            text-decoration: none;
          }
        }

        small {
          font-size: 50%;
          margin: 0 0 0 $spacing-m;
        }
      }
    }
  }

  .html {
    align-self: center;
    max-width: 80rem;
    padding: $spacing-s;
    position: relative;
    width: 100%;
    z-index: 10;
  }

  .leaderboard {
    align-self: center;
    background-color: $color-primary;
    border: 1px solid $color-tertiary;
    display: flex;
    flex-direction: column;
    margin: 0 0 $spacing-m 0;
    max-width: 80rem;
    padding: $spacing-m;
    position: relative;
    width: 100%;
    z-index: 10;

    h2 {
      font-size: 3.6rem;
      margin: 0;
    }

    small {
      color: $color-tertiary;
      font-size: 1.6rem;
      margin: 0 0 $spacing-m 0;
    }

    ol {
      list-style: none;
      margin: 0;
      padding: 0;

      li {
        display: flex;
        flex: 1;
        font-size: 2.4rem;
        // justify-content: space-between;
        margin: 0 0 $spacing-s 0;
        padding: $spacing-xs;

        .username {
          flex: 1;
        }

        .score {
          text-align: right;
          width: 5rem;
        }

        &.highlight {
          background-color: $color-secondary;
          border-radius: 2px;
        }

        &:last-child {
          margin: 0;
        }

        ul {
          padding: $spacing-xs 0 $spacing-xs $spacing-l;
          width: 100%;

          li {
            display: flex;
            flex: 1;
            font-size: 1.6rem;
            justify-content: space-between;
            margin: 0;
            padding: $spacing-xs $spacing-s;
          }
        }
      }
    }

    p {
      font-size: 1.8rem;
    }

    .cta {
      background-color: $color-highlight;
      border-radius: 3px;
      color: $color-foreground;
      font-size: 2rem;
      margin: 0;
      padding: $spacing-s $spacing-m;
      text-align: center;
      text-decoration: none;
    }
  }
}
