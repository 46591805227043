@import '../../style/vars';

.loader {
  align-items: center;
  align-self: center;
  color: $color-foreground;
  display: flex;
  flex-direction: column;
  height: 4rem;
  justify-content: center;
  justify-self: center;
  position: relative;

  .spinner {
    display: flex;
    justify-content: center;
  }

  .text {
    font: inherit;
    position: absolute;
    text-align: center;
    top: 100%;
    width: 20rem;
  }
}
