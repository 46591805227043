.site {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  .content {
    display: flex;
    flex: 1;
    flex-direction: column;
  }
}
