@import '../../style/vars';

.loginButton {
  background-color: $color-secondary;
  border: 1px solid $color-tertiary;
  border-radius: 2px;
  color: $color-foreground;
  cursor: pointer;
  font: inherit;
  font-weight: bold;
  margin-right: $spacing-xs;
  padding: $spacing-xs $spacing-s;
  text-decoration: none;
}

.voteButton {
  background: none;
  border: 0;
  color: $color-foreground;
  cursor: pointer;
  font-size: 2.4rem;
  outline: 0;

  &.voted {
    color: $color-highlight;
  }
}
