@import '../../style/vars';
@import '../../style/form';

.page {
  color: $color-foreground;
  display: flex;
  justify-content: center;

  .wrap {
    display: flex;
    flex-direction: column;
    max-width: 60rem;
    padding: $spacing-m 0;
    width: 100%;

    a {
      color: $color-highlight;

      &.button {
        color: $color-foreground;
      }
    }

    h1 {
      font-size: 4.2rem;
      font-weight: 700;
      line-height: 4.2rem;
      margin: 0 0 $spacing-m 0;
      text-transform: uppercase;
    }

    .formWrap {
      background-color: $color-primary;
      border: 1px solid $color-tertiary;
      border-radius: 2px;
      padding: $spacing-m;

      h2 {
        align-items: center;
        display: flex;
        font-size: 2.8rem;
        font-weight: bold;
        justify-content: space-between;
        margin: 0 0 $spacing-s 0;
      }

      label.header {
        align-items: center;
        display: flex;
        font-size: 2.8rem;
        font-weight: bold;
        justify-content: space-between;
        margin: 0 0 $spacing-s 0;
      }

      .fee {
        background-color: $color-secondary;
        border: 1px solid $color-tertiary;
        border-radius: 2px;
        display: flex;
        font-weight: bold;
        justify-content: space-between;
        margin: 0 0 $spacing-s 0;
        padding: $spacing-s;
      }

      .benefits {
        margin: 0 0 $spacing-s 0;
      }

      .coupon {
        margin: 0 0 $spacing-s 0;
      }

      .error {
        background-color: $color-highlight;
        border-radius: 2px;
        color: $color-foreground;
        margin: 0 0 $spacing-s 0;
        padding: $spacing-xs $spacing-s;
      }

      button {
        max-height: 4rem;
      }

      .info {
        font-weight: bold;
        margin: $spacing-s 0 0 0;
        text-align: center;
      }

      .cardWrap {
        background-color: $color-secondary;
        border: 1px solid $color-tertiary;
        border-radius: 2px;
        color: $color-foreground;
        font: inherit;
        max-height: 4rem;
        padding: $spacing-s $spacing-s;

        &.error {
          border: 1px solid $color-highlight;
          border-radius: 2px 2px 0 0;
        }
      }

      a.button {
        background-color: $color-highlight;
        border: 0;
        border-radius: 2px;
        color: $color-foreground;
        cursor: pointer;
        display: flex;
        font: inherit;
        font-weight: bold;
        justify-content: center;
        margin: $spacing-s 0 0 0;
        padding: $spacing-s $spacing-m;
        text-decoration: none;

        &:focus {
          outline: 1px solid $color-tertiary;
        }

        &:disabled {
          background-color: $color-secondary;
          cursor: default;
          opacity: 0.8;
        }

        .loaderWrap {
          height: 2rem;
        }
      }
    }
  }
}

.overlay {
  align-items: flex-start;
  background-color: rgba(255, 255, 255, 0.25);
  display: flex;
  height: 100vh;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 1000;

  .modal {
    background-color: $color-primary;
    border: 1px solid $color-secondary;
    border-radius: 5px;
    color: $color-foreground;
    display: flex;
    flex-direction: column;
    margin: 15vh 0 0 0;
    max-width: 50rem;
    padding: $spacing-m;
    width: 100%;

    h2 {
      margin: 0 0 $spacing-s 0;
    }

    @media (max-width: 500px) {
      font-size: 4.4vw;

      h2 {
        font-size: 5.6vw;
      }
    }

    button {
      background-color: $color-highlight;
      border: 0;
      border-radius: 2px;
      color: $color-foreground;
      cursor: pointer;
      display: flex;
      font: inherit;
      font-weight: bold;
      justify-content: center;
      margin: $spacing-s 0 0 0;
      padding: $spacing-s $spacing-m;
      text-decoration: none;

      &:focus {
        outline: 1px solid $color-tertiary;
      }

      &:disabled {
        background-color: $color-secondary;
        cursor: default;
        opacity: 0.8;
      }

      .loaderWrap {
        height: 2rem;
      }
    }

    button.link {
      color: $color-foreground;
      background-color: transparent;
      padding: 0;
      text-decoration: underline;
      font-weight: normal;
    }
  }
}
