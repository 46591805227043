@import '../../style/form';
@import '../../style/vars';

.overlay {
  align-items: center;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  height: 100vh;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  width: 100vw;

  .modal {
    background-color: $color-primary;
    color: #fff;
    border: 1px solid $color-tertiary;
    border-radius: 2px;
    max-width: 50rem;
    padding: $spacing-m $spacing-s $spacing-s $spacing-s;
    position: relative;
    width: 100%;

    h2 {
      align-items: center;
      display: flex;
      font-size: 4.2rem;
      font-weight: normal;
      margin: 0 0 $spacing-m 0;

      img {
        margin: 0 0 0 $spacing-s;
        max-height: 4rem;
      }
    }

    .close {
      background: none;
      border: none;
      color: #fff;
      cursor: pointer;
      font-size: 2.4rem;
      position: absolute;
      right: $spacing-s;
      top: $spacing-s;
    }

    .form {
      .group {
        flex-direction: column;
      }
    }

    .checkout {
      border-top: 1px solid $color-tertiary;
      display: flex;
      flex-direction: column;

      h3 {
        font-size: 2.8rem;
        margin: $spacing-m 0 $spacing-s 0;
      }
    }

    .success {
      button {
        background-color: $color-highlight;
        border: none;
        border-radius: 2px;
        color: #fff;
        cursor: pointer;
        font: inherit;
        font-weight: bold;
        padding: $spacing-s $spacing-m;
        width: 100%;
      }
    }
  }
}
