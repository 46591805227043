@import '../../style/form';
@import '../../style/vars';

.page {
  align-items: flex-start;
  background-color: $color-secondary;
  display: flex;
  flex: 1;
  justify-content: center;
  padding: $spacing-l 0 0 0;

  .formWrap {
    background-color: $color-primary;
    border: 1px solid $color-tertiary;
    border-radius: 2px;
    color: $color-foreground;
    padding: $spacing-m;
    max-width: 40rem;
    width: 100%;

    .error {
      background-color: $color-highlight;
      border-radius: 2px;
      margin: 0 0 $spacing-s 0;
      padding: $spacing-s $spacing-s;
    }

    h1 {
      margin: 0 0 $spacing-m 0;
    }

    a {
      color: $color-foreground;
      display: block;
      margin: $spacing-s 0 0 0;
      text-align: right;
    }

    .divider {
      border-bottom: 1px solid $color-foreground;
      color: $color-foreground;
      position: relative;
      max-width: 40rem;
      width: 100%;

      .inner {
        background-color: $color-primary;
        left: 50%;
        position: absolute;
        top: -1.2rem;
        text-align: center;
        transform: translateX(-50%);
        width: 3rem;
      }
    }

    form {
      margin: $spacing-m 0 0 0;
    }

    .registerLink {
      margin: $spacing-m 0 0 0;
      text-align: center;

      a {
        display: inline;
      }
    }
  }
}
