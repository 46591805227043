@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600&family=Roboto:wght@400;500&family=Saira+Stencil+One&display=swap');

* {
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  padding: 0;
}

html {
  font-size: 62.5%;
}

body {
  background-color: #090909;
  font-family: 'Roboto', sans-serif;
  font-size: 1.6rem;
}

::selection {
  background: #922719;
  color: #fff;
}
