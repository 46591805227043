@import '../../style/vars';

.page {
  align-items: center;
  background-color: $color-primary;
  display: flex;
  flex: 1;
  justify-content: center;
  min-height: 100vh;
}
