@import '../../style/vars';

.wrap {
  display: flex;
  flex-direction: column;

  textarea {
    background-color: $color-secondary;
    border: 0;
    border-radius: 2px;
    color: $color-foreground;
    font: inherit;
    min-height: 10rem;
    padding: $spacing-s $spacing-s;

    &:focus {
      outline: 1px solid $color-tertiary;
    }
  }

  .remaining {
    align-self: flex-end;
    color: $color-primary;
    height: 0;
    overflow: hidden;
    transition: height 400ms ease-in-out, color 200ms ease-in-out;

    &.open {
      color: $color-tertiary;
      height: 2.2rem;
      transition: height 200ms ease-in-out, color 400ms ease-in-out;
    }
  }
}
