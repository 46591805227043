@import '../../style/vars';

.comments {
  display: flex;
  flex-direction: column;
  max-width: 120rem;
  padding: 0 $spacing-s;
  width: 100%;

  .login {
    align-self: flex-start;
    background-color: $color-highlight;
    border-radius: 3px;
    color: $color-foreground;
    padding: $spacing-xs $spacing-s;
    text-decoration: none;
  }
}
