@import '../../style/vars';
@import '../../style/form';

.page {
  align-items: center;
  color: $color-foreground;
  display: flex;
  justify-content: center;

  .formWrap {
    background-color: $color-primary;
    border: 1px solid $color-tertiary;
    margin: $spacing-l 0 0 0;
    max-width: 60rem;
    padding: $spacing-m;
    width: 100%;

    h1 {
      margin: 0 0 $spacing-s 0;
    }

    a {
      color: $color-highlight;
    }

    p.success {
      background-color: $color-secondary;
      border-radius: 2px;
      color: $color-foreground;
      padding: $spacing-s;
    }
  }
}
