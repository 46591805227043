@import '../../style/vars';

.page {
  color: $color-foreground;
  display: flex;
  flex-direction: column;

  .hero {
    background-position: center;
    display: flex;
    flex-direction: column;
    height: 90vh;
    margin: 0 0 -9rem 0;
    min-height: 420px;
    position: relative;
    z-index: 5;

    @media (max-width: 675px) {
      height: 80vh;
    }

    .wrap {
      align-items: flex-start;
      background: linear-gradient(
        180deg,
        rgba($color-background, 0.5) 0%,
        rgba($color-background, 0.7) 80%,
        rgba($color-background, 1) 100%
      );
      display: flex;
      flex: 1;
      flex-direction: column;
      justify-content: center;

      .innerWrap {
        align-items: flex-start;
        display: flex;
        font-size: 6.4rem;
        flex-direction: column;
        padding: 0 $spacing-l;

        @media (max-width: 1024px) {
          font-size: 4.8rem;
        }

        @media (max-width: 710px) {
          font-size: 3.6rem;
        }

        h1 {
          font-size: 100%;
          margin: 0 0 $spacing-xs 0;
          text-shadow: #000 0px 0px 3px;
          text-transform: uppercase;

          a {
            color: $color-foreground;
            text-decoration: none;
          }
        }

        small {
          font-size: 50%;
          margin: 0 0 0 $spacing-m;
        }

        .subtitle {
          max-width: 70vw;

          @media (max-width: 1024px) {
            max-width: 100vw;
          }
        }

        .ctaButton {
          background-color: $color-highlight;
          border-radius: 3px;
          color: $color-foreground;
          font-size: 2rem;
          margin: 1.5rem 0 0 $spacing-m;
          padding: $spacing-s $spacing-m;
          text-decoration: none;
        }
      }
    }
  }

  h1 {
    margin: 0 0 $spacing-xs 0;
  }

  a {
    color: $color-foreground;
  }

  .countdown {
    align-items: center;
    background-color: $color-primary;
    border: 1px solid $color-tertiary;
    display: flex;
    justify-content: space-between;
    margin: 0 0 $spacing-s 0;
    padding: $spacing-s $spacing-s $spacing-s $spacing-m;
    position: relative;
    z-index: 10;

    .textWrap {
      align-items: center;
      display: flex;

      .prize {
        margin-right: $spacing-m;

        .fa {
          margin-right: $spacing-s;
        }
      }

      @media (max-width: 960px) {
        align-items: flex-start;
        flex-direction: column;
        justify-content: center;

        .prize {
          margin-bottom: $spacing-s;
        }
      }
    }

    a {
      background-color: $color-highlight;
      border-radius: 2px;
      text-decoration: none;
      padding: $spacing-xs $spacing-s;
    }
  }

  .leaderboard {
    align-self: center;
    background-color: $color-primary;
    border: 1px solid $color-tertiary;
    display: flex;
    flex-direction: column;
    margin: 0 0 $spacing-m 0;
    max-width: 80rem;
    padding: $spacing-m;
    position: relative;
    width: 100%;
    z-index: 10;

    h2 {
      margin: 0 0 $spacing-m 0;
    }

    ol {
      list-style: none;
      margin: 0;
      padding: 0;

      li {
        display: flex;
        padding: 0 0 $spacing-s 0;

        &:last-child {
          padding: 0;
        }

        .place {
          align-items: center;
          display: flex;
          font-size: 4.8rem;
        }

        .info {
          align-items: center;
          display: flex;
          flex: 1;
          justify-content: space-between;
          padding: 0 $spacing-l;

          .user {
            font-size: 3.6rem;
          }

          .votes {
            font-size: 1.8rem;
          }
        }

        .thumbnail {
          position: relative;

          img {
            display: block;
            height: 10rem;
            max-width: 100%;
          }

          .play {
            align-items: center;
            display: flex;
            font-size: 5.6rem;
            justify-content: center;
            opacity: 0;
            transition: opacity 200ms ease-in-out;

            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
          }

          &:hover {
            .play {
              opacity: 0.6;
            }
          }
        }
      }
    }
  }

  .videoWrap {
    background-color: $color-primary;
    display: flex;
    flex-direction: column;
    padding: $spacing-m;

    button {
      background: none;
      border: 0;
      color: $color-foreground;
      cursor: pointer;
      font: inherit;
      font-size: 1.8rem;
      margin: $spacing-s 0 0 0;
      outline: 0;
    }
  }
}
