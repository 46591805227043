@import '../../../../../../style/vars';

.comment {
  margin: 0 0 $spacing-m 0;

  .header {
    align-items: flex-end;
    display: flex;
    margin: 0 0 $spacing-xs 0;

    .commenter {
      color: $color-tertiary;
      margin: 0 $spacing-s 0 0;
      font-style: italic;
    }

    .date {
      color: $color-tertiary;
    }
  }

  .content {
    color: $color-foreground;
  }
}
