@import '../../style/vars';

.page {
  align-items: center;
  color: $color-foreground;
  display: flex;
  flex-direction: column;
  padding: $spacing-m $spacing-m $spacing-m $spacing-m;

  @media (max-width: 1250px) {
    padding: $spacing-m;
  }

  .backButton {
    align-self: flex-start;
    background: none;
    border: 0;
    color: $color-foreground;
    cursor: pointer;
    font: inherit;
    margin: 0 0 $spacing-xs 0;
    text-decoration: underline;
  }

  .videoWrap {
    background-color: $color-primary;
    border: 1px solid $color-tertiary;
    border-radius: 2px;
    display: flex;
    flex: 1;
    flex-direction: column;
    max-width: 120rem;
    min-height: 10rem;
    position: relative;
    width: 100%;

    .wrap404 {
      padding: $spacing-m;

      h1 {
        margin: 0 0 $spacing-s 0;
      }

      a {
        color: $color-foreground;
      }
    }

    .player {
      padding-top: 56%;
      position: relative;

      iframe {
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
      }

      .navButton {
        background-color: $color-primary;
        border: 1px solid $color-tertiary;
        color: $color-foreground;
        font-size: 3.2rem;
        padding: $spacing-s;
        position: absolute;
        top: calc(50% - 33px);
        z-index: 10;

        &.left {
          border-left: 0;
          border-radius: 0 50% 50% 0;
          left: 0;
          padding: $spacing-s $spacing-s $spacing-s $spacing-xs;
        }

        &.right {
          border-right: 0;
          border-radius: 50% 0 0 50%;
          padding: $spacing-s $spacing-xs $spacing-s $spacing-s;
          right: 0;
        }
      }
    }

    .meta {
      padding: $spacing-m;

      .titleBar {
        display: flex;
        flex-direction: column;

        h1 {
          font-size: 3.2rem;
          margin: 0;
        }

        .submitter {
          a {
            color: $color-foreground;
            font-style: italic;
          }
        }

        .controls {
          align-items: center;
          display: flex;

          .secondary {
            background-color: $color-secondary;
            border: 1px solid $color-tertiary;
            border-radius: 2px;
            color: $color-foreground;
            cursor: pointer;
            font: inherit;
            font-weight: bold;
            margin-right: $spacing-xs;
            padding: $spacing-xs $spacing-s;
            text-decoration: none;
          }

          .primary {
            background-color: $color-highlight;
            border: 1px solid $color-tertiary;
            border-radius: 2px;
            color: $color-foreground;
            cursor: pointer;
            font: inherit;
            font-weight: bold;
            padding: $spacing-xs $spacing-s;
            text-decoration: none;
          }

          button:disabled {
            border: 1px solid transparent;
            cursor: default;
            opacity: 0.6;
          }
        }
      }

      .descWrap {
        align-items: flex-end;
        display: flex;

        .desc {
          flex: 1;
        }
      }
    }
  }
}
