@import '../../style/vars';

.page {
  color: $color-foreground;
  display: flex;
  justify-content: center;

  .wrap {
    display: flex;
    flex-direction: column;
    max-width: 60rem;
    padding: $spacing-m 0;
    width: 100%;

    h1 {
      font-size: 4.2rem;
      font-weight: 700;
      line-height: 4.2rem;
      margin: 0 0 $spacing-m 0;
      text-transform: uppercase;
    }

    .formWrap {
      background-color: $color-primary;
      border: 1px solid $color-tertiary;
      border-radius: 2px;
      display: flex;
      flex-direction: column;
      padding: $spacing-m;

      h2 {
        align-items: center;
        display: flex;
        font-size: 2.8rem;
        justify-content: space-between;
        margin: 0 0 $spacing-s 0;
      }

      small {
        color: $color-tertiary;
      }

      button {
        max-height: 4rem;
      }

      a.button {
        align-items: center;
        background-color: $color-highlight;
        border: 0;
        border-radius: 2px;
        color: $color-foreground;
        display: flex;
        font: inherit;
        font-weight: bold;
        justify-content: center;
        margin: $spacing-s 0 0 0;
        padding: $spacing-s;
        text-align: center;
        text-decoration: none;
        width: 100%;

        &.secondary {
          background-color: $color-secondary;
        }

        .icon {
          font-size: 2.4rem;
          margin: 0 $spacing-s 0 0;
        }
      }
    }
  }
}
