@import '../../style/form';
@import '../../style/vars';

.page {
  align-items: center;
  background-color: $color-primary;
  display: flex;
  flex: 1;
  flex-direction: column;

  .logo {
    max-width: 30rem;

    img {
      width: 100%;
    }
  }

  .tagline {
    color: $color-foreground;
    margin: 0 0 $spacing-l 0;
    text-align: center;

    h1 {
      font-size: 4.2rem;
      font-weight: normal;
      margin: $spacing-m 0 0 0;
    }

    h2 {
      font-size: 2.8rem;
      font-weight: normal;
      margin: 0;
    }

    .stars {
      display: flex;
      justify-content: center;
      margin: $spacing-m 0 $spacing-xs 0;

      svg {
        margin: 0 $spacing-xs 0 0;

        &:last-child {
          margin: 0;
        }
      }
    }

    small {
      font-style: italic;
      font-size: 90%;
    }
  }

  .providerWrap {
    margin: 0 0 $spacing-m 0;
    max-width: 40rem;
    width: 100%;

    .errorMessage {
      background-color: $color-highlight;
      border-radius: 3px;
      color: $color-foreground;
      margin: 0 0 $spacing-s 0;
      padding: $spacing-s;
    }
  }

  .divider {
    border-bottom: 1px solid $color-foreground;
    color: $color-foreground;
    position: relative;
    max-width: 40rem;
    width: 100%;

    .inner {
      background-color: $color-primary;
      left: 50%;
      position: absolute;
      top: -1.2rem;
      text-align: center;
      transform: translateX(-50%);
      width: 3rem;
    }
  }

  .formWrap {
    border-radius: 2px;
    margin-top: $spacing-m;
    max-width: 40rem;
    padding: $spacing-s;
    width: 100%;

    .loginLink {
      color: $color-foreground;
      margin: $spacing-s 0 0 0;
      text-align: right;

      a {
        color: $color-highlight;
      }
    }
  }
}
