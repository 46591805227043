@import '../../style/vars';

.page {
  display: flex;
  flex-direction: column;
  padding: $spacing-m;

  .wrapper {
    align-self: center;
    background-color: $color-primary;
    border: 1px solid $color-tertiary;
    border-radius: 2px;
    color: $color-foreground;
    display: flex;
    flex-direction: column;
    padding: $spacing-m;

    h1 {
      color: $color-foreground;
      font-size: 3.2rem;
      font-weight: bold;
      line-height: 42px;
      margin: 0;
    }

    p {
      margin-bottom: $spacing-m;
    }

    a {
      color: $color-highlight;
    }

    .button {
      background-color: $color-highlight;
      border-radius: 2px;
      color: $color-foreground;
      font: inherit;
      font-size: 1.8rem;
      margin: 0 0 $spacing-s 0;
      padding: $spacing-s $spacing-s;
      text-align: center;
      text-decoration: none;

      &.secondary {
        background-color: $color-secondary;
        margin: 0;
      }
    }
  }
}
