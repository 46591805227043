@import '../../style/vars';

.wrapper {
  display: flex;
  justify-content: center;

  .innerWrap {
    display: flex;
    flex-direction: column;

    a,
    button {
      align-items: center;
      border-radius: 2px;
      border: none;
      display: flex;
      font: inherit;
      font-family: 'Roboto', sans-serif;
      font-weight: 500;
      margin: 0 0 $spacing-xs 0;
      min-width: 20rem;
      padding: $spacing-s;
      text-decoration: none;

      .logo {
        font-size: 24px;
        margin: 0 $spacing-s 0 0;
      }

      .text {
        flex: 1;
        text-align: center;
      }

      &.disabled {
        cursor: default;
        opacity: 0.6;
      }

      &.facebook {
        background-color: #4267b2;
        color: #fff;
      }

      &.twitch {
        background-color: #9146ff;
        color: #f0f0ff;
      }

      &.youtube {
        background-color: #f00;
        color: #fff;
      }
    }

    .disconnect {
      background-color: transparent;
      border: 0;
      color: $color-highlight;
      cursor: pointer;
      font: inherit;
      font-size: 1.4rem;
      margin: 0 0 $spacing-m 0;
      outline: 0;
      padding: 0;
      text-decoration: underline;

      &:last-child {
        margin: 0 0 $spacing-xs 0;
      }
    }
  }
}
