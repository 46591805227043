@import '../../style/vars';

.headWrap {
  height: 8rem;
  position: relative;
  width: 100%;
  z-index: 1000;

  &.homeWrap {
    height: 0;
  }
}

.verify {
  align-items: center;
  background-color: $color-highlight;
  color: $color-foreground;
  display: flex;
  height: 5rem;
  justify-content: center;
  padding: 0 $spacing-s;
  position: fixed;
  width: 100%;

  button {
    background: rgba(255, 255, 255, 0.15);
    border: 0;
    border-radius: 2px;
    color: $color-foreground;
    cursor: pointer;
    flex-shrink: 0;
    font: inherit;
    font-weight: 500;
    margin: 0 0 0 $spacing-s;
    padding: $spacing-xs $spacing-s;
  }

  @media (max-width: 640px) {
    flex-direction: column;
    height: 10rem;
    justify-content: center;

    .text {
      margin: 0 0 $spacing-s 0;
    }
  }
}

.header {
  align-items: center;
  background-color: $color-primary;
  box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: space-between;
  padding: $spacing-s 0;
  position: fixed;
  top: 0;
  transition: background-color 200ms ease-in-out, box-shadow 200ms ease-in-out;
  left: 0;
  width: 100%;

  &.home {
    background-color: rgba(0, 0, 0, 0);
    box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0);
  }

  &.home:not([data-scroll='0']) {
    background-color: #000;
    box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.3);
  }

  .left {
    display: flex;
    flex: 1;
  }

  .right {
    display: flex;
  }

  .logo {
    flex: 1;
    margin: 0 0 0 $spacing-m;

    @media (max-width: 500px) {
      margin: 0;
    }

    .logoInner {
      align-items: center;
      display: flex;
      text-decoration: none;

      img {
        display: block;
        height: 6rem;
        margin: 0 $spacing-m 0 0;

        @media (max-width: 500px) {
          margin: 0 $spacing-xs 0 0;
        }
      }

      .text {
        color: $color-foreground;
        font-family: 'Roboto', sans-serif;
        font-size: 2.8rem;
        font-weight: bold;
        position: relative;

        .beta {
          color: $color-highlight;
          font-family: 'Saira Stencil One', cursive;
          font-size: 1.8rem;
          font-weight: normal;
          position: absolute;
          right: -2rem;
          top: calc(100% - 1rem);
          transform: rotate(-20deg);
        }

        @media (max-width: 950px) {
          font-size: 2.3rem;

          .beta {
            font-size: 1.8rem;
            right: -2rem;
            top: calc(100% - 0.8rem);
          }
        }
      }
    }
  }

  .mobileButton {
    background: none;
    border: 0;
    color: $color-foreground;
    cursor: pointer;
    font-size: 3.6rem;
    margin: 0 $spacing-s 0 0;
    padding: 0;
    position: relative;
    z-index: 100;

    @media (min-width: 850px) {
      display: none;
    }

    @media (max-width: 500px) {
      margin: 0 $spacing-xs 0 0;
    }
  }

  .right {
    @media (max-width: 850px) {
      display: none;

      &.open {
        background: $color-primary;
        display: flex;
        flex-direction: column;
        height: 100%;
        left: 0;
        padding: 7.4rem 0 0 0;
        position: fixed;
        top: 0;
        width: 100%;
        z-index: 99;

        ul {
          align-items: stretch;
          border-bottom: 1px solid $color-tertiary;
          border-top: 1px solid $color-tertiary;
          flex: 1;
          flex-direction: column;
          padding: 0;

          li {
            border-bottom: 1px solid $color-tertiary;
            margin: 0;
            padding: $spacing-m;
            position: relative;
            text-align: center;

            .spreader {
              height: 100%;
              left: 0;
              position: absolute;
              top: 0;
              width: 100%;
            }
          }

          li.primary {
            align-self: center;
            border: 0;
            margin: $spacing-m 0;
            padding: 0.5rem 1rem;
          }

          .profile {
            display: none;
          }

          .dropdown {
            display: flex;
            position: relative;

            .arrow {
              display: none;
            }
          }

          .alerts {
            position: absolute;
          }
        }
      }
    }

    nav {
      align-items: center;
      display: flex;

      ul {
        align-items: center;
        color: #fff;
        display: flex;
        list-style: none;
        margin: 0;
        padding: 0 $spacing-m;

        li {
          margin-right: $spacing-m;
          position: relative;

          &:hover {
            background-color: rgba(255, 255, 255, 0.1);
          }

          &.iconDropdown:hover {
            background-color: transparent;
          }

          .notifications {
            cursor: pointer;
            opacity: 0.5;

            &.active {
              opacity: 1;
            }
          }

          .profile {
            cursor: pointer;
            font-size: 2.8rem;
          }

          .menuOpen {
            display: none;
          }

          .menuOpen:checked + .dropdown {
            display: block;
          }

          .alertWrap {
            background-color: $color-primary;
            border: 1px solid $color-tertiary;
            max-width: 30rem;
            position: absolute;
            right: -12px;
            top: calc(100% + 1rem);
            width: 100vw;

            .alerts {
              align-items: stretch;
              display: flex;
              flex-direction: column;
              margin: 0;
              padding: 0;
              position: relative;

              .arrow {
                background-color: $color-primary;
                border: 0;
                border-top: 1px solid $color-tertiary;
                border-left: 1px solid $color-tertiary;
                bottom: calc(100% - 7.5px);
                height: 15px;
                margin: 0;
                padding: 0;
                position: absolute;
                right: 11px;
                transform: rotate(45deg);
                width: 15px;
              }

              li {
                padding: $spacing-s;
                margin: 0;
              }

              li.alert {
                display: flex;
                justify-content: space-between;
                position: relative;

                .spacer {
                  position: absolute;
                  top: 0;
                  left: 0;
                  width: 100%;
                  height: 100%;
                }

                .clear {
                  background: none;
                  border: 0;
                  color: $color-foreground;
                  cursor: pointer;
                  position: relative;
                  z-index: 10;
                }
              }

              li.noAlerts {
                color: $color-tertiary;
                flex: 1;
                margin: 0;
                text-align: center;

                &:hover {
                  background-color: transparent;
                }
              }
            }
          }

          .dropdown {
            background-color: $color-primary;
            border: 1px solid $color-tertiary;
            border-radius: 1px;
            position: absolute;
            right: -5px;
            top: calc(100% + 1rem);

            ul {
              display: flex;
              flex-direction: column;
              list-style: none;
              margin: 0;
              padding: 0;
              position: relative;

              .arrow {
                background-color: $color-primary;
                border: 0;
                border-top: 1px solid $color-tertiary;
                border-left: 1px solid $color-tertiary;
                bottom: calc(100% - 7.5px);
                height: 15px;
                margin: 0;
                padding: 0;
                position: absolute;
                right: 11px;
                transform: rotate(45deg);
                width: 15px;
              }

              li {
                border-bottom: 1px solid $color-tertiary;
                flex: 1;
                margin: 0;
                padding: 1.5rem $spacing-l;
                position: relative;
                text-align: center;
                width: 100%;

                .spacer {
                  position: absolute;
                  top: 0;
                  left: 0;
                  width: 100%;
                  height: 100%;
                }

                &:last-child {
                  border: 0;
                }

                &:hover {
                  background-color: rgba(255, 255, 255, 0.1);
                }
              }
            }
          }

          a {
            color: #fff;
            text-decoration: none;
          }

          &.primary {
            background-color: $color-highlight;
            border-radius: 2px;
            padding: $spacing-xs $spacing-s;
          }
        }
      }
    }
  }
}
