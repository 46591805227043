@import '../../style/vars';
@import '../../style/form';

.page {
  color: $color-foreground;
  display: flex;
  justify-content: center;

  .wrap {
    display: flex;
    flex-direction: column;
    max-width: 60rem;
    padding: $spacing-m 0;
    width: 100%;

    a {
      color: $color-highlight;
    }

    h1 {
      font-size: 4.2rem;
      font-weight: 700;
      line-height: 4.2rem;
      margin: 0 0 $spacing-m 0;
      text-transform: uppercase;
    }

    .formWrap {
      background-color: $color-primary;
      border: 1px solid $color-tertiary;
      border-radius: 2px;
      padding: $spacing-m;

      h2 {
        align-items: center;
        display: flex;
        font-size: 2.8rem;
        justify-content: space-between;
        margin: 0 0 $spacing-s 0;
      }

      .fee {
        align-items: center;
        background-color: $color-secondary;
        border: 1px solid $color-tertiary;
        border-radius: 2px;
        display: flex;
        justify-content: space-between;
        margin: 0 0 $spacing-m 0;
        padding: $spacing-s;

        user-select: none;

        .price {
          align-items: center;
          display: flex;
          font-size: 1.8rem;

          img {
            margin: 0 $spacing-xs 0 0;
            max-height: 2.5rem;
          }
        }
      }

      .error {
        background-color: $color-highlight;
        border-radius: 2px;
        color: $color-foreground;
        padding: $spacing-xs $spacing-s;
      }

      .divider {
        border-bottom: 1px solid $color-secondary;
        margin: $spacing-s 0;
        width: 100%;
      }

      button {
        max-height: 4rem;
      }

      .credits {
        align-items: center;
        display: flex;
        font-size: 1.8rem;
        font-weight: bold;
        justify-content: center;
        margin: 0 0 $spacing-s 0;

        img {
          height: 3rem;
          margin: 0 $spacing-xs 0 0;
        }

        .quantity {
          font-weight: normal;
          margin: 0 0 0 $spacing-xs;
        }
      }

      .highlight {
        font-size: 2.6rem;
        font-weight: bold;
        margin: $spacing-m 0 0 0;
        text-align: center;
        text-transform: uppercase;
      }

      .info {
        font-size: 1.8rem;
        font-weight: bold;
        margin: $spacing-s auto 0 auto;
        text-align: center;
        width: 80%;
      }
    }
  }
}
