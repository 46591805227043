@import '../../style/vars';

.video {
  height: 100%;
  margin: 0 0.2rem;
  position: relative;
  transition: transform 200ms ease-in-out;
  z-index: 5;

  .delete {
    background: rgba(0, 0, 0, 0.5);
    border: 0;
    border: 1px solid $color-foreground;
    color: $color-foreground;
    cursor: pointer;
    outline: 0;
    padding: $spacing-s;
    position: absolute;
    right: 1px;
    top: 1px;
    transition: background 200ms ease-in-out;
    z-index: 10;

    &:hover,
    &:focus {
      background: $color-highlight;
    }
  }

  a {
    text-decoration: none;
  }

  .thumbWrap {
    overflow: hidden;
    padding-top: 56%;
    position: relative;

    img,
    .inner {
      position: absolute;
      top: 50%;
      left: 0;
      height: auto;
      transform: translateY(-50%);
      width: 100%;
      z-index: 5;
    }

    .playIcon {
      color: $color-foreground;
      font-size: 8rem;
      left: calc(50% - 3.5rem);
      opacity: 0;
      position: absolute;
      top: calc(50% - 4rem);
      transition: opacity 200ms ease-in-out;
      z-index: 10;
    }
  }

  &:hover {
    .thumbWrap {
      .playIcon {
        opacity: 0.6;
      }
    }
  }

  .meta {
    color: $color-foreground;
    padding: $spacing-xs $spacing-s 0 0;

    h3 {
      font-size: 1.3rem;
      font-weight: bold;
      margin: 0;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 100%;
    }

    .lower {
      align-items: center;
      display: flex;
      justify-content: space-between;

      .submitter {
        font-size: 1.1rem;
        font-style: italic;
      }

      .views {
        .icon {
          font-size: 1.4rem;
          margin-right: $spacing-s;
        }
      }
    }
  }
}
