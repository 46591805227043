@import '../../style/vars';

.wrap {
  display: flex;
  flex-direction: column;
  margin: 0 auto $spacing-m auto;
  width: 200px;

  .google {
    background-color: #fff;
    border: 0;
    border-radius: 3px;
    color: #333;
    cursor: pointer;
    display: flex;
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    font-weight: 500;
    height: 35px;
    margin: 0 0 $spacing-s 0;
    padding: 8px;
    width: 100%;

    img {
      height: 18px;
      margin: 0 24px 0 0;
      width: 18px;
    }
  }

  .facebook {
    align-items: center;
    background-color: #4267b2;
    border: 0;
    border-radius: 3px;
    color: #fff;
    cursor: pointer;
    display: flex;
    flex: 1;
    font-family: 'Roboto', sans-serif;
    height: 35px;
    padding: 8px;
    width: 100%;

    .logo {
      font-size: 18px;
      line-height: 0;
    }

    .text {
      flex: 1;
      font-family: inherit;
      font-size: 14px;
      font-weight: 500;
    }
  }
}
