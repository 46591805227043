@import '../../style/vars';

.wrap {
  display: flex;

  .month {
    flex-grow: 3;
    margin-right: $spacing-xs;
    width: 0;
  }

  .day {
    flex-grow: 1;
    margin-right: $spacing-xs;
    width: 0;
  }

  .year {
    flex-grow: 2;
    width: 0;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }
}
