@import '../../../../style/vars';

.tournament {
  align-items: center;
  border-bottom: 1px solid $color-secondary;
  display: flex;
  justify-content: space-between;
  padding: $spacing-s;

  @media (max-width: 800px) {
    flex-direction: column;
    justify-content: stretch;
  }

  .meta {
    display: flex;
    flex-direction: column;

    @media (max-width: 800px) {
      margin: 0 0 $spacing-m 0;
    }

    .row {
      align-items: center;
      display: flex;
      margin: 0 0 $spacing-xs 0;

      @media (max-width: 800px) {
        flex-direction: column;
      }

      &:last-child {
        margin: 0;
      }

      h3 {
        font-size: 2.4rem;
        margin: 0 $spacing-m 0 0;
      }

      .prize {
        margin: 0 $spacing-m 0 0;

        .icon {
          margin: 0 $spacing-xs 0 0;
        }
      }

      .icon {
        &.red {
          color: $color-highlight;
        }
      }

      .date {
        margin: 0 $spacing-m 0 0;

        .label {
          font-weight: 500;
        }
      }

      .metric {
        margin: 0 $spacing-m 0 0;
      }

      strong {
        display: inline;
        margin: 0 3px;
      }
    }
  }

  .cta {
    background-color: $color-highlight;
    border-radius: 3px;
    color: $color-foreground;
    padding: $spacing-xs $spacing-s;
    text-decoration: none;

    @media (max-width: 800px) {
      padding: $spacing-s $spacing-m;
      text-align: center;
      width: 100%;
    }
  }

  .entered {
    background-color: $color-secondary;
    border-radius: 2px;
    color: $color-foreground;
    padding: $spacing-xs $spacing-s;
  }
}
