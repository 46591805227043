@import '../../style/form';
@import '../../style/vars';

.formWrap {
  background-color: $color-primary;
  border: 1px solid $color-tertiary;
  border-radius: 2px;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 0 $spacing-s 0;
  max-width: 60rem;
  min-height: 5.5rem;
  padding: $spacing-m;
  width: 100%;

  h2 {
    margin: 0 0 $spacing-m 0;
  }

  .tabs {
    display: flex;

    .tab {
      background-color: darken($color-secondary, 10%);
      border: 0;
      border-bottom: 0;
      color: $color-foreground;
      cursor: pointer;
      font: inherit;
      margin: 0 $spacing-xs 0 0;
      outline: 0;
      padding: $spacing-s;

      &.active {
        background-color: $color-secondary;
        border-color: $color-tertiary;
      }

      img {
        height: 3rem;
        width: auto;
      }

      img.glitch {
        margin-right: $spacing-xs;
      }
    }
  }

  .fbWrap {
    height: auto;
    padding-top: 56.25%;
    position: relative;
    width: 100%;

    iframe {
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }
  }

  iframe {
    border: 1px solid $color-secondary;
  }

  h3 {
    margin: $spacing-s 0 $spacing-m $spacing-xs;
  }

  form {
    display: flex;
  }

  .ctaButton {
    align-self: flex-start;
    background-color: $color-highlight;
    border-radius: 3px;
    color: $color-foreground;
    display: block;
    padding: $spacing-xs $spacing-s;
    text-decoration: none;
  }
}
