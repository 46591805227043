@import '../../../../style/vars';

.details {
  margin: $spacing-m 0;

  .row {
    display: flex;
    justify-content: space-between;
    margin: 0 0 $spacing-s 0;

    &:last-child {
      margin: 0;
    }
  }

  .headline {
    font-size: 2.2rem;
    margin: 0 0 $spacing-s 0;
  }

  .icon {
    margin: 0 $spacing-xs 0;

    &.red {
      color: $color-highlight;
    }
  }

  .label {
    font-weight: 500;
  }

  .expander {
    background: none;
    color: $color-foreground;
    font-weight: normal;
    margin: 0;
    padding: 0;
    text-decoration: underline;
    width: 100%;

    &:focus {
      outline: 0;
    }
  }
}
