$spacing-xs: 0.5rem;
$spacing-s: 1rem;
$spacing-m: 2rem;
$spacing-l: 3rem;

$color-highlight: #922719;
$color-warning: #bd6e06;
$color-foreground: #e8e8e8;
$color-background: #090909;
$color-tertiary: #838383;
$color-secondary: #434343;
$color-primary: #000000;
