@import './vars';

.form {
  color: $color-foreground;
  display: flex;
  flex-direction: column;

  .group {
    display: flex;
    flex-direction: column;
    margin-bottom: $spacing-s;

    &.error {
      position: relative;
      input,
      select,
      textarea {
        border: 1px solid $color-highlight;
        border-radius: 2px 2px 0 0;
      }
    }

    .error {
      background-color: $color-highlight;
      border-radius: 0 0 2px 2px;
      padding: $spacing-xs $spacing-s;
    }

    &.warning {
      position: relative;

      input,
      select,
      textarea {
        border: 1px solid $color-warning;
        border-radius: 2px 2px 0 0;
      }
    }

    .warning {
      background-color: $color-warning;
      border-radius: 0 0 2px 2px;
      padding: $spacing-xs $spacing-s;

      button {
        background: none;
        display: inline;
        font-weight: normal;
        margin: 0;
        padding: 0;
        text-decoration: underline;
      }
    }
  }

  label {
    margin-bottom: $spacing-xs;

    .required {
      color: $color-highlight;
    }
  }

  input,
  textarea,
  select {
    background-color: $color-secondary;
    border: 1px solid $color-tertiary;
    border-radius: 2px;
    color: $color-foreground;
    font: inherit;
    padding: $spacing-s $spacing-s;

    &:focus {
      outline: 1px solid $color-tertiary;
    }

    &:disabled {
      cursor: default;
      opacity: 0.7;
    }
  }

  textarea {
    min-height: 10rem;
  }

  button,
  a.button {
    background-color: $color-highlight;
    border: 0;
    border-radius: 2px;
    color: $color-foreground;
    cursor: pointer;
    display: flex;
    font: inherit;
    font-weight: bold;
    justify-content: center;
    margin: $spacing-s 0 0 0;
    padding: $spacing-s $spacing-m;
    text-decoration: none;

    &:focus {
      outline: 1px solid $color-tertiary;
    }

    &:disabled {
      background-color: $color-secondary;
      cursor: default;
      opacity: 0.8;
    }

    .loaderWrap {
      height: 2rem;
    }
  }

  .errorMessage {
    background-color: $color-highlight;
    border-radius: 0 0 2px 2px;
    padding: $spacing-xs $spacing-s;
  }
}
