@import '../../style/vars';

.page {
  color: $color-foreground;
  display: flex;
  flex-direction: column;

  .hero {
    background-image: url('../../assets/hero-03.jpg');
    background-position: top;
    display: flex;
    flex-direction: column;
    height: 90vh;
    margin: 0 0 -20rem 0;
    min-height: 420px;
    position: relative;
    z-index: 5;

    @media (max-width: 675px) {
      height: 80vh;
    }

    @media (max-width: 710px) {
      margin: 0 0 $spacing-m 0;
    }

    .wrap {
      background: linear-gradient(
        180deg,
        rgba($color-background, 0.1) 0%,
        rgba($color-background, 0.5) 80%,
        rgba($color-background, 1) 100%
      );
      display: flex;
      flex: 1;
      flex-direction: column;
      justify-content: center;

      .innerWrap {
        display: flex;
        flex: 1;
        font-size: 6.4rem;
        margin: -10rem 0 0 0;
        padding: 0 $spacing-l;

        @media (max-width: 1024px) {
          font-size: 4.8rem;
        }

        @media (max-width: 710px) {
          font-size: 3.6rem;
          flex-direction: column;
          margin: 0;
          padding-top: 10rem;
        }

        .textWrap {
          display: flex;
          flex: 1;
          flex-direction: column;
          justify-content: center;
          padding: 0 $spacing-m 0 0;

          h1 {
            font-size: 100%;
            margin: 0 0 $spacing-xs 0;
            text-shadow: #000 0px 0px 3px;
            text-transform: uppercase;

            a {
              color: $color-foreground;
              text-decoration: none;
            }
          }

          small {
            font-size: 50%;
            line-height: 4rem;
            margin: 0 0 0 $spacing-m;
          }
        }

        .videoWrap {
          align-items: center;
          display: none;
          flex: 1;
          opacity: 0;

          .innerVideoWrap {
            border: 5px solid $color-tertiary;
            height: 0;
            left: 0;
            padding-bottom: 56.25%;
            position: relative;
            top: 0;
            width: 100%;

            iframe {
              height: 100%;
              left: 0;
              position: absolute;
              top: 0;
              width: 100%;
            }
          }
        }
      }
    }
  }

  .ribbons {
    display: flex;
    flex-direction: column;
    padding: 0 0 0 $spacing-m;
    position: relative;
    z-index: 10;

    h2 {
      margin: 0;

      a {
        border-bottom: 1px solid transparent;
        color: $color-foreground;
        text-decoration: none;
        transition: border-color 200ms ease-in-out;

        &:hover {
          border-color: $color-foreground;
        }
      }
    }

    .prize {
      margin: 0 $spacing-xs 0 0;
    }

    .info {
      display: flex;

      @media (max-width: 675px) {
        flex-direction: column;
      }
    }

    .subtitle {
      font-size: 1.4rem;
      margin: $spacing-xs $spacing-m 0 0.2rem;
    }
  }

  .contests {
    align-self: center;
    max-width: 80rem;
    position: relative;
    width: 100%;
    z-index: 10;

    a {
      color: $color-foreground;
    }

    h2 {
      margin: $spacing-m 0 $spacing-xs 0;

      small {
        display: inline;
        font-size: 60%;
      }
    }

    small {
      display: block;
      margin: 0 0 $spacing-s 0;
    }
  }
}
