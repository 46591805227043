@import '../../../../style/vars';

.arrow {
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
  border: 0;
  color: $color-foreground;
  cursor: pointer;
  display: flex;
  font-size: 4.8rem;
  height: 100%;
  left: 0;
  justify-content: center;
  position: absolute;
  top: 0;
  width: 7rem;
  z-index: 20;

  @media (max-width: 500px) {
    display: none;
  }
}
