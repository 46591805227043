@import '../../style/vars';

.page {
  color: $color-foreground;
  display: flex;
  flex-direction: column;
  padding: $spacing-m;

  h1 {
    align-self: center;
    display: flex;
    flex-direction: column;
    font-size: 4.2rem;
    font-weight: bold;
    line-height: 42px;
    margin: 0 0 $spacing-m 0;
    max-width: 60rem;
    text-transform: uppercase;
    width: 100%;

    small {
      font-size: 1.4rem;
      text-transform: none;

      a {
        color: $color-foreground;
      }
    }
  }

  .wrap {
    align-self: center;
    background-color: $color-primary;
    border: 1px solid $color-tertiary;
    display: flex;
    flex-direction: column;
    padding: $spacing-m;
    max-width: 60rem;
    width: 100%;

    h2 {
      align-items: center;
      display: flex;
      font-size: 2.8rem;
      justify-content: space-between;
      margin: 0;
      padding: $spacing-xs 0;
    }

    .divider {
      border-bottom: 1px solid $color-tertiary;
      margin: 0 0 $spacing-m 0;
    }

    a.button {
      background-color: $color-highlight;
      border: 0;
      border-radius: 2px;
      color: $color-foreground;
      font: inherit;
      font-weight: bold;
      margin: $spacing-s 0 0 0;
      padding: $spacing-s;
      text-align: center;
      text-decoration: none;

      &.secondary {
        background-color: $color-secondary;
      }
    }
  }
}
