@import '../../style/vars';

.ribbonWrap {
  margin: $spacing-s 0 $spacing-m 0;
  width: 100%;

  .video {
    height: 100%;

    .inner {
      align-items: center;
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: center;
      width: 100%;

      &:hover a {
        color: $color-highlight;
      }

      a {
        align-items: center;
        color: $color-foreground;
        display: flex;
        flex-direction: column;
        text-decoration: none;
        transition: color 200ms ease-in-out;
      }

      .icon {
        font-size: 12rem;
        margin: 0 0 $spacing-s 0;
      }

      .allLabel {
        font-size: 1.8rem;
      }

      .stretch {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
  }
}
