@import '../../../../style/vars';

.clips {
  display: flex;
  flex-direction: column;
  list-style: none;
  margin: 0;
  padding: 0;

  .empty {
    background-color: $color-secondary;
    padding: $spacing-s;
    text-align: center;
  }

  .clip {
    background-color: $color-secondary;
    display: flex;
    justify-content: center;
    position: relative;
    transition: background-color 200ms ease-in-out;
    z-index: 5;

    &:nth-child(odd) {
      background-color: darken($color-secondary, 3%);
    }

    &:hover {
      background-color: darken($color-secondary, 5%);
    }

    button {
      align-items: center;
      background: none;
      border: 0;
      color: $color-foreground;
      cursor: pointer;
      display: flex;
      outline: 0;
      padding: $spacing-xs;
      width: 100%;

      img {
        margin: 0 $spacing-s 0 0;
        max-height: 5rem;
      }
    }
  }

  .more {
    display: flex;
    justify-content: center;

    button {
      background: none;
      border: 0;
      color: $color-foreground;
      cursor: pointer;
      display: block;
      font-size: 1.8rem;
      outline: 0;
      padding: $spacing-m 0 0 0;
      width: 100%;
    }
  }
}
