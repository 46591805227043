@import '../../style/vars';

.videos {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(32rem, 1fr));
  grid-gap: $spacing-s;
  justify-content: center;

  .video {
    outline: 0;
    padding: 0 0 $spacing-m 0;
    position: relative;
    text-align: center;

    &:focus {
      outline: 1px solid $color-highlight;
    }

    h3 {
      margin: 0;
    }

    .thumbWrap {
      border: 1px solid $color-foreground;
      overflow: hidden;
      padding-top: 56.6%;
      position: relative;

      img {
        position: absolute;
        top: 50%;
        left: 0;
        width: 100%;
        height: auto;
        transform: translateY(-50%);
      }
    }
  }

  .more {
    align-items: center;
    background: transparent;
    border: 0;
    color: $color-foreground;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    outline: none;

    .icon {
      font-size: 10rem;
    }

    .text {
      font-size: 2.4rem;
    }
  }
}
