@import '../../style/vars';
@import '../../style/form';

.page {
  display: flex;
  flex-direction: column;
  padding: $spacing-m;

  h1 {
    color: $color-foreground;
    font-size: 3.2rem;
    font-weight: bold;
    line-height: 42px;
    margin: 0 0 $spacing-m 0;
  }

  .formWrap {
    align-self: center;
    background-color: $color-primary;
    border: 1px solid $color-tertiary;
    border-radius: 2px;
    color: $color-foreground;
    max-width: 40rem;
    padding: $spacing-m;
    width: 100%;

    h2 {
      margin: 0 0 $spacing-s 0;
    }

    button {
      background-color: $color-highlight;
      border: 0;
      border-radius: 2px;
      color: $color-foreground;
      cursor: pointer;
      display: flex;
      font: inherit;
      font-weight: bold;
      justify-content: center;
      margin: $spacing-s 0 0 0;
      padding: $spacing-s $spacing-m;
      height: 4.2rem;
      width: 100%;
    }

    a {
      color: $color-foreground;
      display: block;
      margin: 0 0 $spacing-s 0;
    }
  }
}
