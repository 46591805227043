@import '../../style/vars';

.page {
  color: $color-foreground;
  display: flex;
  flex-direction: column;
  padding: $spacing-m 0 0 0;

  .wrapper {
    margin: 0 auto;
    max-width: 122rem;
    width: 100%;

    h1 {
      color: $color-foreground;
      font-size: 4.8rem;
      font-weight: 500;
      letter-spacing: 0.1rem;
      margin: 0 0 $spacing-m 0;
    }

    .leaderboard {
      background-color: #fff;
      border-radius: 10px;
      color: #424242;
      display: flex;
      flex-direction: column;

      .filters {
        align-items: center;
        display: flex;
        justify-content: flex-end;
        padding: $spacing-s;

        select {
          background: none;
          border: 2px solid $color-highlight;
          border-radius: 1.7rem;
          font: inherit;
          outline: none;
          padding: $spacing-xs $spacing-s;
          transition: border-radius 100ms ease-in-out;

          &:active {
            border-radius: 1.7rem 1.7rem 0 0;
          }
        }
      }

      .table {
        background-color: #f4f4f4;
        border-radius: 0 0 10px 10px;
        margin: 0;
        padding: 0 $spacing-s;

        table {
          width: 100%;

          tr {
            td,
            th {
              padding: $spacing-s 0;
              text-align: center;
            }

            &:nth-child(even) {
              td {
                border-top: 1px solid #ddd;
                border-bottom: 1px solid #ddd;

                &:first-child {
                  border-left: 1px solid #ddd;
                  border-radius: 10px 0 0 10px;
                }

                &:last-child {
                  border-right: 1px solid #ddd;
                  border-radius: 0 10px 10px 0;
                }
              }
            }

            td.loader {
              height: 10rem;
            }
          }
        }
      }
    }
  }
}
