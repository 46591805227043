@import '../../style/vars';

.page {
  color: $color-foreground;
  padding: 0 0 $spacing-m 0;

  .hero {
    background-image: url('../../assets/yfn-hero-homepage-q20-2560x1280.jpg');
    background-position: center;
    display: flex;
    flex-direction: column;
    height: 90vh;
    margin: 0 0 -9rem 0;
    min-height: 420px;
    position: relative;
    z-index: 5;

    @media (max-width: 675px) {
      height: 80vh;
    }

    .wrap {
      align-items: flex-start;
      background: linear-gradient(
        180deg,
        rgba($color-background, 0.5) 0%,
        rgba($color-background, 0.7) 80%,
        rgba($color-primary, 1) 100%
      );
      display: flex;
      flex: 1;
      flex-direction: column;
      justify-content: center;

      .innerWrap {
        align-items: flex-start;
        display: flex;
        font-size: 6.4rem;
        flex-direction: column;
        padding: 0 $spacing-l;

        @media (max-width: 1024px) {
          font-size: 4.8rem;
        }

        @media (max-width: 710px) {
          font-size: 3.6rem;
        }

        h1 {
          font-size: 100%;
          margin: 0 0 $spacing-xs 0;
          text-shadow: #000 0px 0px 3px;
          text-transform: uppercase;

          a {
            color: $color-foreground;
            text-decoration: none;
          }
        }

        small {
          font-size: 50%;
          margin: 0 0 0 $spacing-m;
        }

        .ctaButton {
          background-color: $color-highlight;
          border-radius: 3px;
          color: $color-foreground;
          font-size: 2rem;
          margin: 1.5rem 0 0 $spacing-m;
          padding: $spacing-s $spacing-m;
          text-decoration: none;
        }
      }
    }
  }

  .ribbons {
    display: flex;
    flex-direction: column;
    padding: 0 0 0 $spacing-m;
    position: relative;
    z-index: 10;

    h2 {
      margin: 0;

      a {
        border-bottom: 1px solid transparent;
        color: $color-foreground;
        text-decoration: none;
        transition: border-color 200ms ease-in-out;

        &:hover {
          border-color: $color-foreground;
        }
      }
    }

    .prize {
      margin: 0 $spacing-xs 0 0;
    }

    .info {
      display: flex;

      @media (max-width: 675px) {
        flex-direction: column;
      }
    }

    .subtitle {
      font-size: 1.4rem;
      margin: $spacing-xs $spacing-m 0 0.2rem;
    }
  }
}
